export const TRIMMED_SPACE = `{
  id
  owner
  partner_id
  authorized_create
  authorized_read
  authorized_update
  authorized_delete
  context
  createdAt
  updatedAt
  last_seen
}`
