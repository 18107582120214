export const API_KEY = `{
  id
  owner
  partner_id
  auth_token
  authorized_create
  authorized_read
  authorized_update
  authorized_delete
  createdAt
  updatedAt
}`
